import React from "react";
import "./blog-style.css";
const BlogContent = props => {
  return (
    <>
      <div className="blog-details__main">
        
        <div className="blog-details__content">
          <div className="blog-one__meta">
            <a href="#">
              <i className="far fa-calendar-alt"></i> {props.pageContext.date}
            </a>
            {}
          </div>
          <h1>{props.pageContext.postHeader.title}</h1>
          <p></p>  
          <div className="blog-details__image">
          <img
            src={props.pageContext.postHeader.image}
            alt={`Prompt Breeders - ${props.pageContext.postHeader.title} - Generated with DALL.E IA Model`}
            className="img-fluid"
          />
        </div>

          <div
            dangerouslySetInnerHTML={{ __html: props.pageContext.postContent }}
          >
            {}
          </div>
          {}
        </div>
        <div className="blog-details__meta">
          {}
          {}
        </div>
      </div>

      {}
    </>
  );
};

export default BlogContent;
