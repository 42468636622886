import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogContent from "./blog-content";
import Contact from "@components/contact";
// import Comments from "./comments";
import Sidebar from "./sidebar";
const BlogPost = props => {
  const latestPosts = props.pageContext.latestPosts;
  return (
    <section className="blog-details">
      <Container>
        <Row>
          <Col lg={8}>
            <BlogContent pageContext={props.pageContext} />
            {/* <Comments /> */}
          </Col>
          <Contact />
          <Col lg={4}>
            <Sidebar latestPosts={latestPosts} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogPost;
