import React from "react";

const Contact = () => {
  return (
    <section className="mailchimp-one" id="contactform">
      <div className="container wow fadeInUp" data-wow-duration="1500ms">

        <div className="inner-container">
          <div className="mailchimp-one-title">
            <h2>Contact</h2>
            <p>Prompt Breeders advises and develops integrated generative AI solutions within the operational model and architecture of its clients.</p>
            <p>Contact us to discover our solutions in production, and to discuss the opportunities for transforming your model.</p>
            <p>
              <a href="mailto:contact@promptbreeders.com.com?subject=Information Request">contact@promptbreeders.com</a>
            </p>
          </div>


        </div>
      </div>
    </section>
  );
};

export default Contact;
