import React from "react";
import Layout from "@components/layout";
//import HeaderInner from "@components/header/header-inner";
//import MobileNavInner from "@components/mobile-nav-inner";
import Header from "@components/header/header";
import MobileNav from "@components/mobile-nav";
// import PageHeader from "@components/page-header";
import BlogPost from "@components/blog/blog-post";
import Contact from "@components/contact";
import Footer from "@components/footer";
const BlogDetails = ({ pageContext }) => {
  return (
    <Layout pageTitle={pageContext.postHeader.title}>
      <Header />

      <BlogPost pageContext={pageContext} />
      {/* <Subscribe /> */}
      {/*<Contact />*/}
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default BlogDetails;
